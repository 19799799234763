<template>
  <div>
    <DestinationsTable></DestinationsTable>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DestinationsTable from "@/view/pages/destination/components/DestinationsTable";

export default {
  name: "DestinationListings",
  components: {
    DestinationsTable
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "DestinationListings" }]);
  },
};
</script>