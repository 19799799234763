<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Destinasyonlar
          <span class="d-block text-muted pt-2 font-size-sm">Toplam {{ items.length }} adet destinasyon bulunmakta</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link
          :to="{ name: 'create-destination'}"
          v-slot="{ href }"
        >
          <a :href="href" class="btn btn-dark font-weight-bolder font-size-sm">
            <span class="svg-icon">
              <span class="svg-icon">
                <inline-svg src="media/svg/icons/Code/Plus.svg" />
              </span>
            </span>
            Destinasyon Ekle
          </a>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <b-table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        thead-class="mb-8"
        show-empty
        empty-text="Listelenecek herhangi bir şehir bulunamadı!"
        stacked="lg"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(destination)="data">
          <div class="py-2 pl-0">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div class="symbol-label" :style="{ backgroundImage: `url(${data.item.thumbnail})` }"></div>
              </div>
              <div>
                <router-link
                  :to="{ name: 'show-destination', params: { id: data.item.id }}"
                  v-slot="{ href }"
                >
                  <a :href="href" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ data.item.name }}</a>
                </router-link>
                <span class="text-muted font-weight-bold d-block">{{ data.item.country }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #cell(numberOfAttractions)="data">
          <div v-if="data.item.numberOfAttraction > 0">
            <span class="text-muted font-weight-bold">{{ data.item.numberOfAttraction }} Adet Gezilecek Yer</span>
          </div>
          <div v-else>
            <span class="text-muted font-weight-bold">Gezilecek Yer Yok</span>
          </div>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg label-light-success label-inline" v-if="data.item.status == 1">Yayında</span>
          <span class="label label-lg label-light-warning label-inline" v-if="data.item.status == 0">Yayında değil</span>
        </template>
        <template #cell(actions)="data">
          <router-link
            :to="{ name: 'show-destination', params: { id: data.item.id }}"
            v-slot="{ navigate }"
          >
            <b-button class="btn btn-light btn-hover-primary btn-sm" @click="navigate">
              Görüntüle
            </b-button>
          </router-link>
        </template>
        
      </b-table>
      <b-col sm="7" md="6" class="my-1" v-if="items.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          size="lg"
        >
        </b-pagination>
      </b-col>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

export default {
  name: "DestinationsTable",
  data () {
    return {
      isBusy: true,
      items: [],
      fields: [
        { key: 'destination', label: 'Destinasyon', sortable: false, class: 'pl-7' },
        { key: 'numberOfAttractions', label: 'Gezilecek Yer', sortable: false },
        {
          key: 'status',
          label: 'Durum',
          sortable: false,
          sortByFormatted: true,
          filterByFormatted: true
        },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      ApiService.get('destination/getDestinations.req.php')
      .then(({ data }) => {
        this.items = data.destinations;
        // Set the initial number of items
        this.totalRows = this.items.length
        this.isBusy = false;
      })
      .catch(({ response }) => {
        this.isBusy = false;
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
}
</script>